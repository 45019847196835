///
/// Future Imperfect by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Box */

.box {
  border: solid 1px _palette(border);
  margin-bottom: _size(element-margin);
  padding: 1.5em;

  > :last-child,
  > :last-child > :last-child,
  > :last-child > :last-child > :last-child {
    margin-bottom: 0;
  }

  &.alt {
    border: 0;
    border-radius: 0;
    padding: 0;
  }
}
